
//setup vars
const books = [
  {
    id: 1,
    title: "Wherever You Are: My Love Will Find You",
    author: "Nancy Tillman",
    image: "https://m.media-amazon.com/images/I/71vNX+zXC5L._AC_UY218_.jpg"
  },
  {
    id: 2,
    title: "Your Mama",
    author: "NoNieqa Ramos, Jacqueline Alcántara",
    image: "https://images-na.ssl-images-amazon.com/images/I/51bhVEL0tEL._AC_SX184_.jpg"
  },
  {
    id: 3,
    title: "Ariadne: A Novel",
    author: "Jennifer Saint",
    image: "https://images-na.ssl-images-amazon.com/images/I/41AvaBmddFL._AC_SX184_.jpg"
  }
];

export default books;