import React from 'react';
import ReactDom from 'react-dom';
import books from './books'
import SpecificBook from './Book'
import './index.css'

const BookList = () => {
  return (
    <section className="bookList">
      {
        books.map((book) => {
          return <SpecificBook {...book} key={book.id}></SpecificBook>}
        )
      }
    </section>
  );
}
ReactDom.render(<BookList />, document.getElementById("root"));