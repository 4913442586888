const Book = ({image, title, author}) => {
  //attribute, eventHandler
  const clickHandler = (title) => {
    alert(title);
  };

  return (
    <article className="book" onMouseOver={() => console.log(author)}>
      <img src={image} alt=""/>
      <h1>{title}</h1>
      <h4>{author}</h4>
      <button type="button" onClick={() => clickHandler(title)}>Click Me!</button>
      {/* {children} */}
      {/* <p>{let x = 6}</p> */}
    </article>
  );
}

export default Book;